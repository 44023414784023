<template>
  <div id="bristol-menu" class="bristol-menu">
    <div id="header" class="white--text">
      <v-parallax height="400" :src="require('@/assets/bm_background.png')">
        <div class="darken-bg"></div>
        <v-container class="content py-12">
          <v-row>
            <v-col cols="12" lg="9">
              <h3
                class="font-weight-black mb-10"
                :class="{
                  'display-4': $vuetify.breakpoint.smAndUp,
                  'display-2': $vuetify.breakpoint.xsOnly,
                }"
              >
                BRISTOL MENU
              </h3>
              <p class="title mb-4">
                An app for table service, take away and deliveries all in one.
              </p>

              <p class="title font-weight-black">
                Made in Bristol for Bristol.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-parallax>
    </div>
    <div id="features" class="white">
      <v-container class="content py-10">
        <v-row>
          <v-col cols="12" lg="9">
            <div v-for="item in features" :key="item.title">
              <div class="d-flex align-center mt-12 mb-4">
                <v-icon class="pr-6 mb-5" x-large color="orange"
                  >mdi-check</v-icon
                >
                <p
                  class="display-1 font-weight-light orange--text text--darken-2"
                >
                  {{ item.title }}
                </p>
              </div>
              <div class="features-screenshots">
                <vue-preview :slides="item.screenshots" />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div id="form">
      <v-card class="pa-6 white" elevation="20">
        <div class="form-container">
          <h3 class="display-1 mb-4">Connect with us</h3>
          <p class="mb-12">
            Leave your details and we will be in touch shortly!
          </p>
          <form
            name="bm-contact"
            method="post"
            @submit.prevent="handle_submit"
            netlify-honeypot="bot-field"
            netlify
          >
            <v-text-field
              tile
              dense
              outlined
              name="name"
              v-model="form.name"
              label="Name *"
              required
            ></v-text-field>
            <v-text-field
              tile
              dense
              outlined
              name="email"
              v-model="form.email"
              label="Email *"
              required
            ></v-text-field>
            <v-text-field
              tile
              dense
              outlined
              name="business_name"
              v-model="form.business_name"
              label="Business Name *"
              required
            ></v-text-field>
            <v-text-field
              tile
              dense
              outlined
              name="phone"
              v-model="form.phone"
              label="Phone"
            ></v-text-field>
            <v-btn
              v-if="!email_sent"
              block
              rounded
              large
              color="orange"
              class="white--text mt-4"
              :loading="loading"
              :disabled="!form_valid"
              type="submit"
              >Send</v-btn
            >
            <div v-else>
              <p><v-icon class="pr-2">mdi-check</v-icon>Email has been sent!</p>
            </div>
          </form>
        </div>

        <div class="mt-6">
          <v-btn
            x-large
            icon
            href="https://www.facebook.com/BristolMenu"
            target="_blank"
            ><v-icon> mdi-facebook</v-icon></v-btn
          >
          <v-btn
            x-large
            icon
            href="https://twitter.com/bristol_menu"
            target="_blank"
            ><v-icon> mdi-twitter</v-icon></v-btn
          >
        </div>
      </v-card>
    </div>
    <div id="social"></div>
    <div id="footer" class="grey lighten-2">
      <v-container class="content">
        <v-row>
          <v-col cols="12" md="3">
            <router-link to="/">
              <v-img
                class="mb-6"
                width="180px"
                :src="require('@/assets/wearelocals_logo_small.png')"
              />
            </router-link>
            <ul>
              <li><router-link to="/">Home</router-link></li>
            </ul>
          </v-col>
        </v-row>
        <div class="copy caption mt-12">
          &copy; WeAreLocals 2020
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "BristolMenu",
  data() {
    return {
      features: [
        {
          title: "Slick front-end",
          screenshots: [
            {
              src: require("@/assets/screenshots/bm_landing_page.jpg"),
              msrc: require("@/assets/screenshots/thumbnails/thumb_bm_landing_page.jpg"),
              alt: "#wearebs3 Landing Page",
              title: "#wearebs3 Landing Page",
              w: 1850,
              h: 939,
            },
            {
              src: require("@/assets/screenshots/bm_front_end.jpg"),
              msrc: require("@/assets/screenshots/thumbnails/thumb_bm_front_end.jpg"),
              alt: "#wearebs3 Landing Page",
              title: "#wearebs3 Landing Page",
              w: 1850,
              h: 939,
            },
          ],
        },
        {
          title: "Multiple service methods",
          screenshots: [
            {
              src: require("@/assets/screenshots/bm_service_method.jpg"),
              msrc: require("@/assets/screenshots/thumbnails/thumb_bm_service_method.jpg"),
              alt: "Shops Browser",
              title: "Shops Browser",
              w: 1850,
              h: 939,
            },
          ],
        },
        {
          title: "Intuitive back end management tool",
          screenshots: [
            {
              src: require("@/assets/screenshots/settings_general.jpg"),
              msrc: require("@/assets/screenshots/thumbnails/thumb_settings_general.jpg"),
              alt: "Admin Panel Settings",
              title: "Admin Panel Settings",
              w: 1850,
              h: 939,
            },
            {
              src: require("@/assets/screenshots/settings_orders.jpg"),
              msrc: require("@/assets/screenshots/thumbnails/thumb_settings_orders.jpg"),
              alt: "Admin Panel Order Settings",
              title: "Admin Panel Order Settings",
              w: 1850,
              h: 939,
            },
            {
              src: require("@/assets/screenshots/settings_products.jpg"),
              msrc: require("@/assets/screenshots/thumbnails/thumb_settings_products.jpg"),
              alt: "Admin Panel Products Settings",
              title: "Admin Panel Products Settings",
              w: 1850,
              h: 939,
            },
          ],
        },
      ],
      form: {
        name: "",
        email: "",
        business_name: "",
        phone: "",
      },
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 30 || "Name must be less than 30 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      loading: false,
      email_sent: false,
    };
  },
  computed: {
    form_valid() {
      let f = this.form;
      return f.name && f.email && f.business_name;
    },
  },
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    async handle_submit() {
      this.loading = true;
      try {
        await fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: this.encode({
            "form-name": "bm-contact",
            ...this.form,
          }),
        });
        this.email_sent = true;
        this.loading = false;
        setTimeout(() => {
          this.email_sent = false;
          this.clear_form();
        }, 5000);
      } catch {
        this.email_sent = false;
        this.loading = false;
      }
    },
    clear_form() {
      this.form = {
        name: "",
        email: "",
        business_name: "",
        phone: "",
      };
    },
  },
};
</script>

<style lang="scss">
.bristol-menu {
  min-height: 100vh;
  font-family: "Open Sans";
  background: rgba(255, 151, 42, 1);
  background: linear-gradient(
    20deg,
    rgb(168, 88, 2) 0%,
    rgba(255, 151, 42, 1) 100%
  );
}
.features-screenshots {
  margin: 0 75px;
}

.my-gallery {
  display: flex;
  flex-wrap: wrap;

  figure {
    img {
      width: 220px;
    }
  }
  > * {
    margin: 0 10px 20px;
    transition: transform 0.1s ease-in-out;

    &:hover {
      transform: scale(1.02);
    }
  }
}

#form {
  margin: 100px 0;
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    margin: 0;
    position: fixed;
    top: 15%;
    left: 70%;
    width: 400px;
  }
}

#footer {
  .content {
    height: 100%;
  }
  ul {
    li {
      list-style-type: none;
      a {
        color: #000000;
      }
    }
  }
}

.darken-bg {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.45);
}
</style>
